import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import Authenticate from './authenticate'
import { AuthProvider } from '@/contexts/auth_context'

import Layout from './layout'
import Projects from './projects/projects'
import NewProject from './projects/new_project'
import EditProject from './projects/edit_project'
import Sources from './sources/sources'
import NewSource from './sources/new_source'
import EditSource from './sources/edit_source'
import Categories from './categories/categories'
import NewCategory from './categories/new_category'
import EditCategory from './categories/edit_category'
import Users from './users/users'
import NewUser from './users/new_user'
import NewSession from './sessions/new_session'

const App = props => {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Switch>
            <Route path="/login">
              <NewSession />
            </Route>
          </Switch>
          <Authenticate>
            <Switch>
              <Route path="/projects/:projectId/users/new">
                <NewUser />
              </Route>
              <Route path="/projects/:projectId/users">
                <Users />
              </Route>
              <Route path="/projects/:projectId/categories/new">
                <NewCategory />
              </Route>
              <Route path="/projects/:projectId/categories/:id/edit">
                <EditCategory />
              </Route>
              <Route path="/projects/:projectId/categories">
                <Categories />
              </Route>
              <Route path="/projects/:projectId/sources/new">
                <NewSource />
              </Route>
              <Route path="/projects/:projectId/sources/:id/edit">
                <EditSource />
              </Route>
              <Route path="/projects/:projectId/sources">
                <Sources />
              </Route>
              <Route path="/projects/new">
                <NewProject />
              </Route>
              <Route path="/projects/:id/edit">
                <EditProject />
              </Route>
              <Route exact path="/">
                <Projects />
              </Route>
            </Switch>
          </Authenticate>
        </Layout>
      </Router>
    </AuthProvider>
  )
}

export default App
