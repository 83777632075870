import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { get } from '@rails/request.js'

import Project from './project'

const Projects = (props) => {
  const [projects, setProjects] = useState([])

  async function loadProjects () {
    const response = await get('/api/v1/projects.json')
    if (response.ok) setProjects(await response.json || [])
  }

  useEffect(() => {
    loadProjects()
  }, [])

  const removeItem = (id) => {
    setProjects(
      projects.filter((project) => { return project.id !== id })
    )
  }

  return (
    <>
      <div className="headline-with-actions">
        <h1>Projects</h1>
        <Link to="/projects/new" className="btn">Add Project</Link>
      </div>
      <div className="card-grid">
        {projects.map((project) =>
          <Project key={project.id} project={project} onDelete={removeItem} />
        )}
      </div>
    </>
  )
}

export default Projects

// get('/api/v1/projects.json')
// .then(response => response.json)
// .then(data => setProjects(data))
