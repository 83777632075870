import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Form from './form'

export default (props) => {
  const { projectId, id } = useParams()
  const history = useHistory()

  const handleSubmit = () => {
    history.push(`/projects/${projectId}/sources`)
  }

  return (
    <div className="max-w-lg mx-auto">
      <div className="headline-with-actions">
        <h1>Edit Source</h1>
      </div>
      <div className="form-card-wrapper">
        <div className="form-card">
          <Form onSubmit={handleSubmit} method="put" url={`/api/v1/projects/${projectId}/sources/${id}.json`} projectId={projectId} />
        </div>
      </div>
    </div>
  )
}
