import React from 'react'

import Nav from './nav'

export default (props) => {
  return (
    <header>
      <Nav />
    </header>
  )
}
