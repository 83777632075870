import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Form from './form'
import { AuthContext } from '@/contexts/auth_context'

export default (props) => {
  const history = useHistory()
  const { user, setUser } = useContext(AuthContext)

  const handleSubmit = (data) => {
    setUser(data)
    history.push('/')
  }

  return (
    <div className="max-w-lg mx-auto">
      <div className="headline-with-actions">
        <h1>Log In</h1>
      </div>
      <div className="form-card-wrapper">
        <div className="form-card">
          <Form onSubmit={handleSubmit} method="post" url={'/api/v1/sessions.json'} />
        </div>
      </div>
    </div>
  )
}
