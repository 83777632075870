import React, { useState, useEffect } from 'react'
import { get } from '@rails/request.js'

import { useForm } from '@/helpers/use_form'
import { TextInput, SelectInput } from '@/helpers/inputs'

import Kind from '@/models/kind'

export default (props) => {
  const initialValues = {}
  const [state, setState] = useState({ values: initialValues, errors: [] })
  const { handleChange } = useForm(state, setState, props)

  const handleSubmit = (event) => {
    event.preventDefault()
    props.onSubmit(state.values)
  }

  const kinds = Kind.all.map(item => ({ name: item.label, id: item.value }))

  const [categories, setCategories] = useState([])
  async function loadCategories () {
    const response = await get(`/api/v1/projects/${props.projectId}/categories.json`)
    if (response.ok) setCategories(await response.json)
  }

  const [tags, setTags] = useState([])
  async function loadTags () {
    const response = await get(`/api/v1/projects/${props.projectId}/tags.json`)
    if (response.ok) {
      const tags = (await response.json).map(item => ({ name: item.name, id: item.name }))
      setTags(tags)
    }
  }

  useEffect(() => {
    loadCategories()
    loadTags()
  }, [])

  return (
    <form onSubmit={handleSubmit} noValidate="novalidate" acceptCharset="UTF-8">
      <div className='grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-4'>
        <SelectInput name="category" options={categories} values={state.values} errors={state.errors}
          placeholder="All Categories"
          onChange={(event) => handleChange(event, handleSubmit)} />
        <SelectInput name="kind" options={kinds} values={state.values} errors={state.errors}
          placeholder="All Types"
          onChange={(event) => handleChange(event, handleSubmit)} />
        <SelectInput name="tag" options={tags} values={state.values} errors={state.errors}
          placeholder="All Tags"
          onChange={(event) => handleChange(event, handleSubmit)} />
        <TextInput name="q" values={state.values} errors={state.errors}
          placeholder="Title or abstract"
          onChange={(event) => handleChange(event, handleSubmit)} />
      </div>
    </form>
  )
}
