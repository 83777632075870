import { matchPath, useLocation } from 'react-router-dom'

function projectIdFromCurrentPath () {
  const { pathname } = useLocation()
  const match = matchPath(pathname, { path: '/projects/:projectId' })
  const projectId = match?.params?.projectId

  return projectId !== 'new' ? projectId : null
}

export { projectIdFromCurrentPath }
