import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Form from './form'

export default (props) => {
  const { projectId } = useParams()
  const history = useHistory()

  const handleSubmit = () => {
    history.push(`/projects/${projectId}/categories`)
  }

  return (
    <div className="max-w-md mx-auto">
      <div className="headline-with-actions">
        <h1>New Category</h1>
      </div>
      <div className="form-card-wrapper">
        <div className="form-card">
          <Form onSubmit={handleSubmit} method="post" url={`/api/v1/projects/${projectId}/categories.json`} projectId={projectId} />
        </div>
      </div>
    </div>
  )
}
