import React from 'react'
import { Link } from 'react-router-dom'
import { destroy } from '@rails/request.js'

export default (props) => {
  const deleteItem = (event) => {
    destroy(
      `/api/v1/projects/${props.projectId}/sources/${props.source.id}.json`
    ).then((response) => {
      if (response.ok) props.onDelete(props.source.id)
    })
  }

  return (
    <tr className="source">
      <td>
        {props.source.title}
        {props.source.url &&
          <a target="_blank" href={props.source.url} rel="noreferrer">&nbsp;+</a>
        }
      </td>
      <td className="hidden md:table-cell">{props.source.kind}</td>
      <td className="hidden md:table-cell">{props.source.category?.name}</td>
      <td className="action">
        <Link to={`/projects/${props.projectId}/sources/${props.source.id}/edit`} className="mr-2">Edit</Link>
        <button onClick={deleteItem} className="btn-link ml-2">Destroy</button>
      </td>
    </tr>
  )
}
