import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { get } from '@rails/request.js'
import { AuthContext } from '@/contexts/auth_context'

export default (props) => {
  const history = useHistory()
  const { user, setUser } = useContext(AuthContext)

  async function loadSession () {
    const response = await get('/api/v1/session.json')
    if (response.ok) {
      setUser(await response.json)
    } else {
      history.push('/login')
    }
  }

  useEffect(() => {
    loadSession()
  }, [])

  if (user) {
    return (props.children)
  } else {
    return null
  }
}
