import React from 'react'

import AuthorFields from './author_fields'

export default (props) => {
  const handleChange = (event, index) => {
    const name = event.target.name
    const value = event.target.value

    const state = { ...props.state }
    state.values.authors[index][name] = value
    props.setState(state)
  }

  const handleAdd = (index) => {
    const state = { ...props.state }
    state.values.authors.push({})
    props.setState(state)
  }

  const handleRemove = (index) => {
    const state = { ...props.state }
    const author = state.values.authors[index]

    if (author.id) {
      author._destroy = true
    } else {
      state.values.authors.splice(index, 1)
    }

    props.setState(state)
  }

  return (
    <div className="nested-form">
      {props.state.values.authors.map((author, index) =>
        <AuthorFields key={index}
          index={index}
          author={author}
          onChange={handleChange}
          onRemove={handleRemove}
        />
      )}
      <div className="action">
        <button type="button" onClick={handleAdd} className="btn-link">Add Author</button>
      </div>
    </div>
  )
}
