import React from 'react'

export default (props) => {
  const inputId = [props.model || 'field', props.name].join('_')

  return (
    <div className="input string">
      <label className="string" htmlFor={inputId}>
        {props.label}
      </label>
      <textarea className="text" name={props.name} value={props.values[props.name] || ''} onChange={props.onChange}
        id={inputId} />
      <div className="error">{props.errors[props.name]}</div>
    </div>
  )
}
