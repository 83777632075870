import React from 'react'
import { useHistory } from 'react-router-dom'
import Form from './form'

export default (props) => {
  const history = useHistory()
  const handleSubmit = () => {
    history.push('/')
  }

  return (
    <div className="max-w-md mx-auto">
      <div className="headline-with-actions">
        <h1>New Project</h1>
      </div>
      <div className="form-card-wrapper">
        <div className="form-card">
          <Form onSubmit={handleSubmit} method="post" url="/api/v1/projects.json" />
        </div>
      </div>
    </div>
  )
}
