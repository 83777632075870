import React from 'react'
import { Link } from 'react-router-dom'
import { destroy } from '@rails/request.js'

export default (props) => {
  const deleteItem = (event) => {
    destroy(
      `/api/v1/projects/${props.project.id}.json`
    ).then((response) => {
      if (response.ok) props.onDelete(props.project.id)
    })
  }

  return (
    <div className="card">
      <div className="title">
        <Link to={`/projects/${props.project.id}/sources`} className="title--link">{props.project.name}</Link>
      </div>
      <div className="actions">
        <Link to={`/projects/${props.project.id}/edit`} className="mr-2">Edit</Link>
        <button onClick={deleteItem} className="btn-link ml-2">Destroy</button>
      </div>
    </div>
  )
}
