import React, { useState } from 'react'

const AuthContext = React.createContext()

const AuthProvider = (props) => {
  const [user, setUser] = useState()
  const value = { user, setUser }

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
