import { useContext } from 'react'
import { AuthContext } from '@/contexts/auth_context'

function currentUser () {
  const { user } = useContext(AuthContext)
  const isLoggedIn = !!user
  const userName = user ? user.name : ''

  return { loggedInUser: user, userName, isLoggedIn }
}

export { currentUser }
