import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { get } from '@rails/request.js'

import { loadRecord, useForm } from '@/helpers/use_form'
import { TextInput, TextArea, RadioGroupInput, SelectInput } from '@/helpers/inputs'

import Kind from '@/models/kind'
import NestedAuthors from './nested_authors'

export default (props) => {
  const isEditing = props.method === 'put'
  const initialValues = { kind: 'book', authors: [{}] }

  const [state, setState] = useState({ values: initialValues, errors: [] })
  const { handleChange, handleSubmit } = useForm(state, setState, props)

  const [categories, setCategories] = useState([])
  async function loadCategories () {
    const response = await get(`/api/v1/projects/${props.projectId}/categories.json`)
    if (response.ok) setCategories(await response.json)
  }

  useEffect(() => {
    loadCategories()
    if (isEditing) loadRecord(props.url).then(data => setState({ values: data, errors: [] }))
  }, [])

  return (
    <form onSubmit={handleSubmit} noValidate="novalidate" acceptCharset="UTF-8">
      <div className='form-inputs'>
        <RadioGroupInput name="kind" options={Kind.all} values={state.values} errors={state.errors} onChange={handleChange} />
        <TextInput name="title" label="Title" values={state.values} errors={state.errors} onChange={handleChange} />
        <NestedAuthors state={state} setState={setState} />
        { ['website'].includes(state.values.kind) &&
          <TextInput name="url" label="Url" values={state.values} errors={state.errors} onChange={handleChange} />
        }
        { ['magazine_article'].includes(state.values.kind) &&
          <TextInput name="publication" label="Publication" values={state.values} errors={state.errors} onChange={handleChange} />
        }
        { ['magazine_article'].includes(state.values.kind) &&
          <div className="grid grid-cols-2 gap-x-3">
            <TextInput name="volume" label="Volume" values={state.values} errors={state.errors} onChange={handleChange} />
            <TextInput name="issue" label="Issue" values={state.values} errors={state.errors} onChange={handleChange} />
          </div>
        }
        <div className="grid grid-cols-2 gap-x-3">
          { ['book', 'magazine_article'].includes(state.values.kind) &&
            <TextInput name="pages" label="Pages" values={state.values} errors={state.errors} onChange={handleChange} />
          }
          { ['book'].includes(state.values.kind) &&
            <TextInput name="isbn" label="ISBN" values={state.values} errors={state.errors} onChange={handleChange} />
          }
          { ['magazine_article'].includes(state.values.kind) &&
            <TextInput name="issn" label="ISSN" values={state.values} errors={state.errors} onChange={handleChange} />
          }
        </div>
        <TextArea name="abstract" label="Abstract" values={state.values} errors={state.errors} onChange={handleChange} />
        <TextInput name="tag_names_string" label="Tag Names" values={state.values} errors={state.errors} onChange={handleChange} />
        <SelectInput name="category_id" label="Category" options={categories} values={state.values} errors={state.errors} onChange={handleChange} />
      </div>
      <div className='form-actions'>
        <input type="submit" value={`${!isEditing ? 'Create' : 'Update'} Source`} className="btn w-full" />
        <Link className="form-text-link" to={`/projects/${props.projectId}/sources`}>Back</Link>
      </div>
    </form>
  )
}
