import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { get } from '@rails/request.js'

import Source from './source'
import FilterForm from './filter_form'

const Sources = (props) => {
  const { projectId } = useParams()
  const [sources, setSources] = useState([])

  async function loadSources (data = {}) {
    const response = await get(`/api/v1/projects/${projectId}/sources.json`, { query: data })
    if (response.ok) setSources(await response.json || [])
  }

  useEffect(() => {
    loadSources()
  }, [])

  const handleSubmit = (data) => {
    loadSources(data)
  }

  const removeItem = (id) => {
    setSources(
      sources.filter((source) => { return source.id !== id })
    )
  }

  return (
    <>
      <div className="headline-with-actions">
        <h1>Sources</h1>
        <Link to={`/projects/${projectId}/sources/new`} className="btn">Add Source</Link>
      </div>
      <div className="filter-section">
        <FilterForm projectId={projectId} onSubmit={handleSubmit} />
      </div>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th className="w-full">Title</th>
              <th className="hidden md:table-cell">Type</th>
              <th className="hidden md:table-cell">Category</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {sources.map((source) =>
              <Source key={source.id} source={source} projectId={projectId} onDelete={removeItem} />
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Sources

// get('/api/v1/sources.json')
// .then(response => response.json)
// .then(data => setSources(data))
