import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { get } from '@rails/request.js'

import Category from './category'

const Categories = (props) => {
  const { projectId } = useParams()
  const [categories, setCategories] = useState([])

  async function loadCategories () {
    const response = await get(`/api/v1/projects/${projectId}/categories.json`)
    if (response.ok) setCategories(await response.json || [])
  }

  useEffect(() => {
    loadCategories()
  }, [])

  const removeItem = (id) => {
    setCategories(
      categories.filter((category) => { return category.id !== id })
    )
  }

  return (
    <>
      <div className="headline-with-actions">
        <h1>Categories</h1>
        <Link to={`/projects/${projectId}/categories/new`} className="btn">Add Category</Link>
      </div>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th className="w-full">Name</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {categories.map((category) =>
              <Category key={category.id} category={category} projectId={projectId} onDelete={removeItem} />
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Categories
