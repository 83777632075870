import React from 'react'

export default (props) => {
  const inputId = [props.model || 'field', props.name].join('_')
  const type = props.type ? props.type : 'text'

  return (
    <div className="input string">
      { props.label &&
        <label className="string" htmlFor={inputId}>{props.label}</label>
      }
      <input className="string"
        type={type}
        name={props.name}
        value={props.values[props.name] || ''}
        onChange={props.onChange}
        id={inputId}
        autoFocus={props.autoFocus ? props.autoFocus : false}
        placeholder={props.placeholder && props.placeholder}
      />
      { props.errors &&
        <div className="error">{props.errors[props.name]}</div>
      }
    </div>
  )
}
