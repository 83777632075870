import React from 'react'

import { TextInput } from '@/helpers/inputs'

export default (props) => {
  const handleChange = (event) => {
    props.onChange(event, props.index)
  }

  const isHidden = (props.author._destroy === true)
  if (isHidden) return null

  return (
    <div className="nested-fields flex">
      <div className="flex-grow">
        <div className="grid grid-cols-2 gap-x-3">
          <TextInput name="first_name" label="First Name" values={props.author} onChange={handleChange} />
          <TextInput name="last_name" label="Last Name" values={props.author} onChange={handleChange} />
        </div>
      </div>
      <div className="flex-none self-end ml-3">
        <button type="button" className="btn btn--substract" onClick={event => props.onRemove(props.index)}>-</button>
      </div>
    </div>
  )
}
