import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { loadRecord, useForm } from '@/helpers/use_form'
import { TextInput } from '@/helpers/inputs'

export default (props) => {
  const isEditing = props.method === 'put'
  const initialValues = { name: '' }

  const [state, setState] = useState({ values: initialValues, errors: [] })
  const { handleChange, handleSubmit } = useForm(state, setState, props)

  useEffect(() => {
    if (isEditing) loadRecord(props.url).then(data => setState({ values: data, errors: [] }))
  }, [])

  return (
    <form onSubmit={handleSubmit} noValidate="novalidate" acceptCharset="UTF-8">
      <div className='form-inputs'>
        <TextInput name="name" label="Name" values={state.values} errors={state.errors} onChange={handleChange} autoFocus="autofocus" />
      </div>
      <div className='form-actions'>
        <input type="submit" value={`${!isEditing ? 'Create' : 'Update'} Category`} className="btn w-full" />
        <Link className="form-text-link" to={`/projects/${props.projectId}/categories`}>Back</Link>
      </div>
    </form>
  )
}
