import React from 'react'
import { destroy } from '@rails/request.js'

export default (props) => {
  const deleteItem = (event) => {
    destroy(
      `/api/v1/projects/${props.projectId}/users/${props.user.id}.json`
    ).then((response) => {
      if (response.ok) props.onDelete(props.user.id)
    })
  }

  return (
    <tr className="user">
      <td>
        {props.user.name}
      </td>
      <td>
        {props.user.email}
      </td>
      <td className="action">
        { props.loggedInUser.id !== props.user.id &&
          <button onClick={deleteItem} className="btn-link ml-2">Remove</button>
        }
      </td>
    </tr>
  )
}
