import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { destroy } from '@rails/request.js'
import { projectIdFromCurrentPath } from '@/helpers/project'
import { currentUser } from '@/helpers/auth'

import LogoImage from '@/images/logo.svg'

export default (props) => {
  const { isLoggedIn, userName } = currentUser()

  const projectId = projectIdFromCurrentPath()
  const hasProjectId = !!projectId

  async function handleLogout () {
    const response = await destroy('/api/v1/session.json')
    if (response.ok) window.location = '/login'
  }

  return (
    <nav className="bg-gray-800">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex-1 flex items-center justify-start sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/">
                <img className="h-4" src={LogoImage} />
              </Link>
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                { hasProjectId &&
                  <>
                  <NavLink to={`/projects/${projectId}/sources`} className="nav-item">Sources</NavLink>
                  <NavLink to={`/projects/${projectId}/categories`} className="nav-item">Categories</NavLink>
                  <NavLink to={`/projects/${projectId}/users`} className="nav-item">Team</NavLink>
                  </>
                }
              </div>
            </div>
          </div>
          <div className="hidden sm:block absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            { isLoggedIn &&
              <>
              <span className="text-gray-100 text-sm sm:text-base">{ userName }</span>
              <button className="ml-1 nav-item" onClick={handleLogout}>Log out</button>
              </>
            }
          </div>
          { isLoggedIn &&
            <>
            <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
              {/* Mobile menu button */}
              <button type="button" style={{ touchAction: 'manipulation' }} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                <span className="sr-only">Open main menu</span>
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            </>
          }
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state */}
      <div className="hidden sm:hidden" id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1 text-right">
          { hasProjectId &&
            <>
            <NavLink to={`/projects/${projectId}/sources`} className="nav-item block">Sources</NavLink>
            <NavLink to={`/projects/${projectId}/categories`} className="nav-item block">Categories</NavLink>
            <NavLink to={`/projects/${projectId}/users`} className="nav-item block">Team</NavLink>
            </>
          }
          { isLoggedIn &&
            <button className="nav-item block" onClick={handleLogout}>Log out</button>
          }
        </div>
      </div>
    </nav>
  )
}
