import React from 'react'

export default (props) => {
  const inputId = [props.model || 'field', props.name].join('_')

  return (
    <div className="input select">
      { props.label &&
        <label className="select" htmlFor={inputId}>{props.label}</label>
      }
      <select className="select"
        name={props.name}
        id={inputId}
        value={props.values[props.name] || ''}
        onChange={props.onChange}>
          <option value="" label={props.placeholder || ' '}></option>
          { props.options.map(option =>
            <option key={option.id} value={option.id}>{option.name}</option>
          )}
      </select>
      <div className="error">{props.errors[props.name]}</div>
    </div>
  )
}
