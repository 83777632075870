import { FetchRequest, get } from '@rails/request.js'

async function loadRecord (url) {
  const response = await get(url)
  if (response.ok) return await response.json
}

function useForm (state, setState, props) {
  const setErrors = (errors) => {
    const newState = { ...state }
    newState.errors = errors
    setState(newState)
  }

  const setValue = (name, value) => {
    const newState = { ...state }
    newState.values[name] = value
    setState(newState)
  }

  const handleChange = (event, callback) => {
    const target = event.target
    const name = target.name
    const value = target.type === 'checkbox' ? target.checked : target.value

    setValue(name, value)
    if (callback) callback(event)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const request = new FetchRequest(props.method, props.url, { body: JSON.stringify(state.values) })
    const response = await request.perform()
    if (response.ok) {
      props.onSubmit(await response.json)
    } else {
      setErrors(await response.json)
    }
  }

  return { handleChange, handleSubmit }
}

export { loadRecord, useForm }
