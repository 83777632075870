import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Form from './form'

export default (props) => {
  const history = useHistory()
  const { id } = useParams()

  const handleSubmit = () => {
    history.push('/')
  }

  return (
    <div className="max-w-md mx-auto">
      <div className="headline-with-actions">
        <h1>Edit Project</h1>
      </div>
      <div className="form-card-wrapper">
        <div className="form-card">
          <Form onSubmit={handleSubmit} method="put" url={`/api/v1/projects/${id}.json`} />
        </div>
      </div>
    </div>
  )
}
