import React from 'react'

export default (props) => {
  const inputId = [props.model || 'field', props.name].join('_')

  return (
    <div className="input radio_buttons">
      { props.options.map(option =>
        <span key={option.value} className='radio'>
          <input
            className="radio_buttons"
            type="radio"
            value={option.value || ''}
            name={props.name}
            checked={props.values[props.name] === option.value}
            onChange={props.onChange}
            id={[inputId, option.value].join('_')} />

            <label className="collection_radio_buttons" htmlFor={[inputId, option.value].join('_')}>
              {option.label}
            </label>
        </span>
      )}
      <div className="error">{props.errors[props.name]}</div>
    </div>
  )
}
