import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { get } from '@rails/request.js'
import { currentUser } from '@/helpers/auth'

import User from './user'

const Users = (props) => {
  const { projectId } = useParams()
  const [users, setUsers] = useState([])

  async function loadUsers () {
    const response = await get(`/api/v1/projects/${projectId}/users.json`)
    if (response.ok) setUsers(await response.json || [])
  }

  useEffect(() => {
    loadUsers()
  }, [])

  const removeItem = (id) => {
    setUsers(
      users.filter((user) => { return user.id !== id })
    )
  }

  const { loggedInUser } = currentUser()

  return (
    <>
      <div className="headline-with-actions">
        <h1>Users</h1>
        <Link to={`/projects/${projectId}/users/new`} className="btn">Add Member</Link>
      </div>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th className="">Name</th>
              <th className="w-full">Email</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {users.map((user) =>
              <User key={user.id} user={user} projectId={projectId} onDelete={removeItem} loggedInUser={loggedInUser} />
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Users
