import React, { useState } from 'react'

import { useForm } from '@/helpers/use_form'
import { TextInput } from '@/helpers/inputs'

export default (props) => {
  const initialValues = {}

  const [state, setState] = useState({ values: initialValues, errors: [] })
  const { handleChange, handleSubmit } = useForm(state, setState, props)

  return (
    <form onSubmit={handleSubmit} noValidate="novalidate" acceptCharset="UTF-8">
      <div className='form-inputs'>
        <TextInput type="email" name="email" label="Email" values={state.values} errors={state.errors} onChange={handleChange} autoFocus="autofocus" />
        <TextInput type="password" name="password" label="Password" values={state.values} errors={state.errors} onChange={handleChange} />
      </div>
      <div className='form-actions'>
        <input type="submit" value="Log In" className="btn w-full" />
      </div>
    </form>
  )
}
