import React from 'react'

import Header from './header'

export default (props) => (
  <>
  <Header />
  <main>
    <div className="max-w-5xl mx-auto py-6 sm:px-6 lg:px-8 sm:mt-10">
      { props.children }
    </div>
  </main>
  </>
)
